<template>
    <v-row justify="center">
        <v-col cols="12">
            <p class="subheading font-weight-regular">
          {{$t('enter_donation')}}
        </p>
        </v-col>
        <!-- <v-col cols="12">
            <v-row justify="center">
           <v-radio-group v-model="amt" row>
               <v-radio label="$5" color="secondary" value = "5" @click="displayValue"></v-radio>
               <v-radio label="$10" color="secondary" value = "10" @click="displayValue"></v-radio>
                <v-radio label="$15" color="secondary" value = "15" @click="displayValue"></v-radio>
           </v-radio-group>
           </v-row>
        </v-col> -->
        <v-col lg="6" cols="12">
               <v-currency-field
                 :label="$t('amt_cad')"
                 outlined
                 :rules = "amtRules"
                 prepend-inner-icon="mdi-currency-usd"
                 v-model="donation"/>
        </v-col>
    </v-row>
</template>
<script>
export default {
  data: () => ({
    donation: '1',
    valid: ''
  }),
  computed: {
    amtRules () {
      return [
        v => !!v || this.$t('enter_amt'),
        v => v > 0 || this.$t('valid_amount'),
        v => v >= 1 || this.$t('min_donation')
        // v => v < 9999 || 'Thank you for your extreme generousity. Please en'
      ]
    },
    totalAmt () {
      const amt = Number.parseFloat(this.donation) * 100
      const preciseAmt = amt.toPrecision(13)
      // var tot = amt * Math.round(num)
      return Number(preciseAmt)
    }
  },
  methods: {
    displayValue () {
      // console.log('Donation Amount :', this.totalAmt)
      // console.log(' The number of tickets selected :', this.tickets)
    },
    setStoreValues () {
      // console.log('Calculated total :', this.totalAmt)
      this.$store.dispatch('totalAction', { amount: this.totalAmt })
    }
  }
}
</script>
<i18n>
{
  "en":{
    "enter_donation":"Please enter your donation amount",
    "enter_amt":"Please enter an amount",
    "valid_amount":"Please enter a valid amount",
    "min_donation":"Minimum donation amount is $1",
    "amt_cad":"Amount in CAD"
  },
  "fr":{
    "enter_donation":"Veuillez inscrire le montant de votre don",
    "enter_amt":"Veuillez entrer un montant",
    "valid_amount":"Veuillez entrer un montant valide",
    "min_donation":"Le montant minimum de donation est de 1$",
    "amt_cad":"Montant en CAD"
  },
  "es":{
    "enter_donation":"Por favor, indique el importe de su donación",
    "enter_amt":"Por favor, introduzca un importe",
    "valid_amount":"Por favor, introduzca un importe válido",
    "min_donation":"El importe mínimo para hacer una donación es de 1$",
    "amt_cad":"Importe en CAD"
  }
}
</i18n>
